import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


// 引入网关入库的store.js
import gateWayWareHousing from "./gateWayWareHousing/gateWayWareHousing"
// 引入物联网卡管理
import nicManage from "./nicManage/nicManage";
// 引入退库申请
import withDrawalApplication from "./withDrawalApplication/withDrawalApplication";
// 引入客户信息
import customerInformation from "./customerInformation/customerInformation";
// 引入用户详情
import userDetail from './userDetail/userDetail';
// 引入客户设备绑定
import customerEquipment from "./customerEquipment/customerEquipment";
// 引入设备详情
import equipmentDetail from "./equipmentDetail/equipmentDetail";
// 代理商管理
import agentManage from "./agentManage/agentManage";
// 引入代理商详情
import agentDetail from "./agentDetail/agentDetail";
// 引入采购管理
import purchaseManage from "./purchaseManage/purchaseManage";
// 引入采购详情
import purchaseDetail from './purchaseDetail/purchaseDetail';
// 引入产品信息维护 
import productInformMaintain from "./productInformMaintain/productInformMaintain";
// 引入报警信息
import warningInform from "./warningInform/warningInform";
// 引入离线设备
import offlineDevices from "./offlineDevices/offlineDevices";
// 引入账户管理
import accountManage from "./accountManage/accountManage";
// 引入日志管理
import journalManage from "./journalManage/journalManage";
// 项目报备
import projectFiling from "./projectFiling/projectFiling";
export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters:{
   
  },
  modules: {
    gateWayWareHousing,//网关管理
    nicManage,//物联网卡管理
    withDrawalApplication,//退库申请
    customerInformation,//客户信息
    userDetail,//用户详情
    customerEquipment,//客户设备绑定
    equipmentDetail,//设备详情
    agentManage,//代理商管理
    agentDetail,//代理商详情
    purchaseManage,//采购管理
    purchaseDetail,//采购详情
    productInformMaintain,//产品信息维护
    warningInform,//报警信息
    offlineDevices,//离线设备
    accountManage,//账户管理
    journalManage,//日志管理
    projectFiling,//项目报备
  },
})
