export default {
  namespaced: true,
  state: {
    obj:{
      isShow:false
    },
    //保存基本数据
    options1: [
      {
        id: 1,
        value1: '全部',
        label: '全部',
      },
      {
        id: 2,
        value1: '123121',
        label: '123121',
      },
      {
        id: 3,
        value1: '123122',
        label: '123122',
      },
      {
        id: 4,
        value1: '123123',
        label: '123123',
      },
      {
        id: 5,
        value1: '123124',
        label: '123124',
      },
    ],
    options2: [
      {
        id: 1,
        value2: '全部',
        label: '全部',
      },
      {
        id: 2,
        value2: '供货商A',
        label: '供货商A',
      },
      {
        id: 3,
        value2: '供货商B',
        label: '供货商B',
      },
      {
        id: 4,
        value2: '供货商C',
        label: '供货商C',
      },
      {
        id: 5,
        value2: '供货商D',
        label: '供货商D',
      },
    ],
    models:[//// 型号
      {
        id:1,
        value:'lb-net-001',
        label:'lb-net-001'
      },
      {
        id:2,
        value:'lb-net-002',
        label:'lb-net-002'
      },
      {
        id:3,
        value:'lb-net-003',
        label:'lb-net-003'
      },
    ],
    acModthArr:[
      {
        id:1,
        value:1,
        label:'4G'
      },
      {
        id:2,
        value:2,
        label:'WiFi'
      },
      
    ],
    // 分页的页数
    currentPage: 1,
    // 最多10个网关设备
    multDeice:[
    
    ],
    agreeApplication:{ // 退库的isShow -- 是否展示dialog
      isShow:false
    },
    multship:{ //批量入库的isShow  -- 是否展示dialog
      isShow:false
    },
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    options1(state) {
      return state.options1
    },
    value(state) {
      return state.value
    },
    tableData(state){
      return state.tableData
    }
  },
}
