export default {
  namespaced: true,
  state: {
    tableData:[
      {
        id:1,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:2,
        userName:'admin',
        loginAccount:'admin',
        phone:13800001252,
        loginTime:'2022-11-12  16:02:03',
        ip:'1.1.1.1',
      },
      {
        id:3,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:4,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:5,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:6,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:7,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:8,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:9,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
      {
        id:10,
        userName:'admin',//用户名
        loginAccount:'admin',//登录账号
        phone:13800001252,//手机号
        loginTime:'2022-11-12  16:02:03',//登录时间
        ip:'1.1.1.1',//登录IP
      },
    ]
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
   
  },
}
