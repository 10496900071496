export default {
  namespaced: true,
  state: { //定义数据
    options1:[//跟进状态的数据
    {
      id:0,
      value2: 0,
      label: '全部'
    }, 
    {
      id:1,
      value2: 1,
      label: '潜在用户'
    }, 
    {
      id:2,
      value2: 2,
      label: '已合作'
    }, 
    {
      id:3,
      value2: '3',
      label: '已放弃'
    },
  ],

    options2:[//公司性质的数据
    {
      id:0,
      value3: 0,
      label: '全部'
    },
      { 
      id:1,
      value3: 1,
      label: '私企'
    }, 
    {
      id:2,
      value3: 2,
      label: '央国企'
    }, 
    {
      id:3,
      value3: 3,
      label: '外企'
    },],
    displayObj:{ //新建用户的是都显示
      isshow:false
    },
    form:{
      cardid:'',//营业执照号 唯一编码，公司为统一的社会信用代码，个人的话则是个人身份证
      gname:'',//企业名称
      gurl:'',//主体链接
      gtype:'',//客户类型
      cid:'1',//所属代理商
      selectedOptions:[],//所在区域--选择的地区
      prov:'',//省份
      city:'',//城市
      status:'',//跟进状态
      detailAddress:[],//详细地址
      detailValue:'',//用户输入的地址
      addr:'',//详细地址
      guser:'',//项目负责人
      gphone:'',//负责人联系方式
      saler:'',//销售员
      salephone:'',//销售员联系方式
      beizhu:'',//备注信息
      hetong:'',//合同
      zhengjian:'',//证件
      lon:'',//经度
      lat:'',//纬度
      gjuser:'',//紧急联系人
      gjphone:'',//紧急联系人的联系方式
    },
    // 客户类型
    clientType:[
      {
        id:1,
        value:1,
        label:'民企',
      },
      {
        id:2,
        value:2,
        label:'国央企',
      },
      {
        id:3,
        value:3,
        label:'外企'
      }
    ],
    // 跟进状态
    followStatus:[
      {
        id:1,
        value:1,
        label:'潜在用户'
      },
      {
        id:2,
        value:2,
        label:'已合作'
      },
      {
        id:3,
        value:3,
        label:'已放弃'
      },
    ]
  },
  mutations: { //修改数据的方法
    

  },
  actions: { //异步操作
    
  },
  getters: { //返回state中的数据
    
  },
}
