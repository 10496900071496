export default {
  namespaced: true,
  state: {
    // 所属级别
    options2:[
      {
        id:1,
        value2:1,
        label:'总代理'
      },
      {
        id:2,
        value2:2,
        label:'省代理'
      },
      {
        id:3,
        value2:3,
        label:'市代理'
      },
      {
        id:4,
        value2:4,
        label:'区代理'
      },
    ],
    formShow:{//是否显示表单
      isshow:false,
    },
    // 企业性质
    companies:[
      {
        id:1,
        value:1,
        label:'民企'
      },
      {
        id:2,
        value:2,
        label:'国央企'
      },
      {
        id:3,
        value:3,
        label:'外企'
      },
    ],
    // 代理商类别
    agentTypes:[
      {
        id:1,
        value:1,
        label:'总代理'
      },
      {
        id:2,
        value:2,
        label:'省代理'
      },
      {
        id:3,
        value:3,
        label:'市代理'
      },
      {
        id:4,
        value:4,
        label:'区级代理'
      }
    ],
    levels:[
      {
        id:1,
        value:1,
        label:'总代理'
      },
      {
        id:2,
        value:2,
        label:'省代理'
      },
      {
        id:3,
        value:3,
        label:'市代理'
      },
      {
        id:4,
        value:4,
        label:'区级代理'
      }
    ],

  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    
  },
}
