export const netSeries = (nid) => {
    if(nid.length === 26 && nid.split('-').length === 3) {
        return 'K1'
    } else if(nid.length === 24 && nid.split('-').length < 3) {
        return 'K2'
    } else {
        return '其他'
    }
}
export const timestamp = (timestamp, format) => {
	timestamp = timestamp ? timestamp : null;
	let date = new Date(timestamp * 1000); //传入的时间戳为10位
	let Y = date.getFullYear() + '-';
	let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const dates = {
        'YYYY-MM-DD': Y + M + D,
        'YYYY-MM-DD HH:mm': Y + M + D + h + m,
        'YYYY-MM-DD HH:mm:ss': Y + M + D + h + m + s,
        'YYYY-MM': Y + M ,
    }
	return dates[format] || Y + M + D + h + m + s
}

// 表格内容本地导出
import * as XLSX from 'xlsx';
export const exportXlsx = (headers, properties, data, name) => {
    const table = document.createElement('table');

    const list = data.map((item) => properties.map((key) => item[key] || ''));

    {
        const tr = document.createElement('tr');
        headers.forEach((header) => {
            const th = document.createElement('th');
            th.innerText = header;
            tr.appendChild(th);
        });
        table.appendChild(tr);
    }

    {
        list.forEach((item) => {
            const tr = document.createElement('tr');
            for (const d of item) {
                const td = document.createElement('td');
                td.innerText = d;
                tr.appendChild(td);
            }
            table.appendChild(tr);
        });
    }

    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, `${name}.xlsx`);
}

export default {
	install(Vue) {
		Vue.prototype.$netSeries = netSeries // 网关系列
        Vue.prototype.$timestamp = timestamp // 时间戳转时间
        Vue.prototype.$exportXlsx = exportXlsx // 表格内容本地导出
        Vue.prototype.$baseUrl = 'https://eleapi.fediot.net' // 接口基础路径
	}
}