export default {
  namespaced: true,
  state: {
    display:{//dialog的是否显示
      isShow:false,
    },
    options1:[
      {
        id:1,
        value1:'普通用户',
        label:'普通用户',
      },
      {
        id:2,
        value1:'中级用户',
        label:'中级用户',
      },
      {
        id:3,
        value1:'高级用户',
        label:'高级用户',
      },
      {
        id:4,
        value1:'VIP用户',
        label:'VIP用户',
      },
    ],
    options2:[
      {
        id:1,
        value2:'待处理',
        label:'待处理'
      },
      {
        id:2,
        value2:'代发货',
        label:'代发货'
      },
      {
        id:3,
        value2:'已完成',
        label:'已完成'
      },
    ],
    options3:[
      {
        id:1,
        value3:'代发货',
        label:'代发货'
      },
      {
        id:2,
        value3:'已发货',
        label:'已发货'
      },
    ],
    // 列表页的表格数据
    tableData:[
      {
        id:1,
        ordersNumber:20221123001,//订单号
        ordersClients:'XXXXX有限公司',//订单客户
        purchaseData:'2022-11-12',//采购日期
        contractStatus:true,//合同状态
        purchaseSum:'120万',//采购金额
        ordersStatus:'2',//订单状态  0--已完成 1--待发货 2--待处理 3--处理中
      },
      {
        id:2,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'1',
      },
      {
        id:3,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'2',
      },
      {
        id:4,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'3',
      },
      {
        id:5,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'2',
      },
      {
        id:6,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'2',
      },
      {
        id:7,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'0',
      },
      {
        id:8,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'0',
      },
      {
        id:9,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'0',
      },
      {
        id:10,
        ordersNumber:20221123001,
        ordersClients:'XXXXX有限公司',
        purchaseData:'2022-11-12',
        contractStatus:true,
        purchaseSum:'120万',
        ordersStatus:'0',
      },
    ],
    //表单数据
    form:{
      agents:'',//代理商
      clients:'',//订单客户
      addressee:'',//收件人
      phone:'',//联系电话
      province:'',//省
      detailAddress:'',//详细地址
      gatewayNumber:1,//网关数量
      loopNumber1:1,//回路数量1
      loopNumber2:1,//回路数量2
      loopNumber3:1,//回路数量3
      loopNumber4:1,//回路数量4
      checkedLoop1:'',//选择的回路1
      checkedLoop2:'',//选择的回路2
      checkedLoop3:'',//选择的回路3
      checkedLoop4:'',//选择的回路4
      moldedShellNumber:1,//塑壳数量
      effectiveTime:'',//生效时间
      relevancyDoc:'',//关联项目
      amount:'',//采购金额
      ordersNumber:0,//订单号
    },
    loopName:[
      {
        id:1,
        value:'1P'
      },
      {
        id:2,
        value:'2P'
      },
      {
        id:3,
        value:'3P'
      },
      {
        id:4,
        value:'4P'
      },
    ],
    // 定义规则rules
    rules:{
      agents:[
        { required: true, message: '请选择代理商', trigger: 'change' }
      ],
      addressee:[
        { required: true, message: '请选择填写收件人', trigger: 'blur' }
      ],
      phone:[
        { required: true, message: '请选择填写联系电话', trigger: 'blur' },
        { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
      ],
      detailAddress:[
        { required: true, message: '请选择填写您的详细地址', trigger: 'blur' },
      ],
      gatewayNumber:[
        { required: true, message: '请选择填写您的网关数量', trigger: 'blur' },
      ],
      loopNumber:[
        { required: true, message: '请选择回路数量', trigger: 'blur' },
      ],
      moldedShellNumber:[
        { required: true, message: '请选择塑壳数量', trigger: 'change' }
      ],
      effectiveTime:[
        { required: true, message: '请选择生效时间', trigger: 'change' }
      ],
      relevancyDoc:[
        { required: true, message: '请选择要关联的项目', trigger: 'change' }
      ],
      amount:[
        { required: true, message: '请输入采购金额', trigger: 'blur' }
      ],
      purchaseAccount:[
        { required: true, message: '请上传采购合同', trigger: 'change' }
      ]
    }
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    
  },
}
