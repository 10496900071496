export default {
  namespaced: true,
  state: {
    tableData:[
      {
        id:1,
        status:'离线',//离线
        gatewayNumber:123123,//网关编号
        gatewayName:'一楼网关',//网关名称
        communicationMethod:'4G',//通讯方式
        installationTime:'2022-11-12',//安装时间
        loopNumber:16,//回路数
        owningUser:'XXXXX有限公司',//所属用户
        offlineData:'2022-11-12  12:00:00',//离线时间
      },
      {
        id:2,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:3,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:4,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:5,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:6,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:7,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:8,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:9,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
      {
        id:10,
        status:'离线',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        communicationMethod:'4G',
        installationTime:'2022-11-12',
        loopNumber:16,
        owningUser:'XXXXX有限公司',
        offlineData:'2022-11-12  12:00:00',
      },
    ]
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
   
  },
}
