export default {
  namespaced: true,
  state: {
    formsObj:{ //表单页的显示与隐藏
      isshow:false
    },
    withDrawObj:{ //退库申请的显示与隐藏
      isshow:false
    },
    multShipObj:{ //批量入库的显示与隐藏
      isshow:false
    },
    //保存基本数据
    options1: [
      {
        id: 1,
        value: '123123',
        label: '123123',
      },
      {
        id: 2,
        value: '123124',
        label: '123124',
      },
      {
        id: 3,
        value: '123124',
        label: '123124',
      },
      {
        id: 4,
        value: '123125',
        label: '123125',
      },
      {
        id: 5,
        value: '123126',
        label: '123126',
      },
    ],

    keyword1: '全部',
    // 表格数据
    tableData: [
      {
        id:1,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:2,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:3,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:4,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:5,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:6,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:7,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:8,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:9,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      {
        id:10,
        numbering: '123123',
        operators: '中国移动',
        combo: '100M/月',
        purchaseData:'2022-11-12',
        inboundTime:"2022-11-14"
      },
      
    ],
    // 分页的页数
    currentPage: 1,
    // 最多10个网卡设备
    multDeice:[
      {id:1,numbering:"123FR4G156"},
      {id:2,numbering:"123FR4G156"},
      {id:3,numbering:"123FR4G156"},
      {id:4,numbering:"123FR4G156"},
      {id:5,numbering:"123FR4G156"},
      {id:6,numbering:"123FR4G156"},
      {id:7,numbering:"123FR4G156"},
      {id:8,numbering:"123FR4G156"},
      {id:9,numbering:"123FR4G156"},
      {id:10,numbering:"123FR4G156"},
    ],
    // 网卡入库的表单form
    form:
      {
        operators:'',//运营商
        combo:'',//套餐
        communiateMethod:'',//通信方式
        purchaseData:'',//采购日期
        numbering:'',//网卡编号
      },
      // 运营商
    operatorsArr:[
      {
        id:1,
        value:'中国联通',
        label:'中国联通'
      },
      {
        id:2,
        value:'中国移动',
        label:'中国移动'
      },
      {
        id:3,
        value:'中国电信',
        label:'中国电信'
      },
      
    ],
    comboArr:[//套餐
      {
        id:1,
        value:'100M/月',
        label:'100M/月'
      },
      {
        id:2,
        value:'500M/月',
        label:'500M/月'
      },
      {
        id:3,
        value:'700M/月',
        label:'700M/月'
      },
      {
        id:4,
        value:'1G/月',
        label:'1G/月'
      },
    ],
    acModthArr:[
      {
        id:1,
        value:'2G',
        label:'2G'
      },
      {
        id:2,
        value:'3G',
        label:'3G'
      },
      {
        id:3,
        value:'4G',
        label:'4G'
      },
      {
        id:4,
        value:'5G',
        label:'5G'
      },
    ],
    rules:{ //定义表单验证规则
      operators:[
        { required: true, message: '请选择运营商', trigger: 'change' }
      ],
      combo:[
        { required: true, message: '请选择套餐', trigger: 'change' }
      ],
      communiateMethod:[
        { required: true, message: '请选择通讯方式', trigger: 'change' }
      ],
      purchaseData:[
        { required: true, message: '请输入采购日期', trigger: 'blur' }
      ],
      numbering:[
        { required: true, message: '请输入要添加的网关编号', trigger: 'blur' },
        { pattern:/^[a-zA-Z0-9]{12}$/, message: '只能输入数字和字母', trigger: 'blur' }
      ],
    }
  },
  mutations: {
    
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
   
  },
}
