export default {
  namespaced: true,
  state: {
    agentArr:[//代理商
      {
        id:1,
        value:'全部',
        label:'全部'
      },
      {
        id:2,
        value:'总代理',
        label:'总代理'
      },
      {
        id:3,
        value:'独家代理',
        label:'独家代理'
      },
      {
        id:4,
        value:'地区代理',
        label:'地区代理'
      },

    ],
    statusArr:[//状态
      {
        id:1,
        value:'全部',
        label:'全部'
      },
      {
        id:2,
        value:'已审核',
        label:'已审核'
      },
      {
        id:3,
        value:'待审核',
        label:'待审核'
      },
      {
        id:4,
        value:'报备到期',
        label:'报备到期'
      },
      {
        id:5,
        value:'报备失效',
        label:'报备失效'
      },


    ],
    tableData:[
      {
        id:1,
        number:221123001,//编号
        agent:'****地区代理商',//代理商
        affiliatedArea:'江苏省南通市',//所属地区
        customerName:'XXXXX有限公司',//客户名称
        projectName:'小学智能用电',//项目名称
        leader:'王五',//负责人
        predictedTime:'2022-11-12',//预计时间
        filingStatus:1,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',//申请时间
        filingDuration:'2022-11-12',//报备有效期
      },
      {
        id:2,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:1,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:3,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:0,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:4,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:2,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:5,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:0,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:6,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:0,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:7,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:3,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:8,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:2,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:9,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:0,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
      {
        id:10,
        number:221123001,
        agent:'****地区代理商',
        affiliatedArea:'江苏省南通市',
        customerName:'XXXXX有限公司',
        projectName:'小学智能用电',
        leader:'王五',
        predictedTime:'2022-11-12',
        filingStatus:3,//报备状态 0--已审核 1--待审核 2--报备到期 3--报备失效
        applicationTime:'2022-11-12',
        filingDuration:'2022-11-12',
      },
    ],
    audit:{//项目审核
      radio:"1",//单选框
      text:'',//文本域的内容
    },
    auditRules:{//审核规则
      radio:[
        { required: true, message: '请选择同意或不同意', trigger: 'change' }
      ]
    },
    cancel:{
      radio:"1",
      marks:'',//
    },
    cancelRules:{
      radio:[
        { required: true, message: '请选择同意或不同意', trigger: 'change' }
      ]
    },
    postpone:{//报备延期
      time:'',//延长时间
      marks:'',//备注说明
    },
    postponeRules:{
      time:[
        { required: true, message: '请选择延长时间', trigger: 'change' }
      ],
      marks:[
        { required: true, message: '请输入备注说明', trigger: 'blur' }
      ]
    },
    times:[
      {
        id:1,
        value:'7天',
        label:'7天'
      },
      {
        id:2,
        value:'15天',
        label:'15天'
      },
      {
        id:3,
        value:'30天',
        label:'30天'
      },
    ]
   
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    
  },
}
