export default {
  namespaced: true,
  state: {
    form:{
      agent:'XXXXX有限公司',//代理商
      orderClient:'',//订单客户
      addressee:'李四',//收件人
      tel:13800001252,//联系电话
      province:'',//省
      city:'',//市
      county:'',//区/县
      addressDetail:'',//详细地址
      value1:'',//快递公司
      courierCompanies:[
        {
          id:1,
          value:'顺丰快递',
          label:'顺丰快递'
        },
        {
          id:2,
          value:'京东快递',
          label:'京东快递'
        },
        {
          id:3,
          value:'圆通快递',
          label:'圆通快递'
        },
        {
          id:4,
          value:'申通快递',
          label:'申通快递'
        },
        {
          id:5,
          value:'极兔快递',
          label:'极兔快递'
        },
      ],//快递公司
      trackingNumber:null,//快递单号
    }
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    
  },
}
