export default {
  namespaced: true,
  state: {
    agentTypes:[
      {
        id:1,
        value:1,
        label:'总代理'
      },
      {
        id:2,
        value:2,
        label:'省代理'
      },
      {
        id:3,
        value:3,
        label:'市代理'
      },
      {
        id:4,
        value:4,
        label:'区级代理'
      }
    ],
    levels:[
      {
        id:1,
        value:1,
        label:'总代理'
      },
      {
        id:2,
        value:2,
        label:'省代理'
      },
      {
        id:3,
        value:3,
        label:'市代理'
      },
      {
        id:4,
        value:4,
        label:'区级代理'
      }
    ],
    
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    
  },
}
