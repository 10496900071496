export default {
  namespaced: true,
  state: {
    warningSort:[
      {
        id:0,
        value:'',
        label:'全部'
      },
      {
        id:1,
        value:1,
        label:'欠压报警'
      },
      {
        id:2,
        value:2,
        label:'欠压告警'
      },
      {
        id:3,
        value:3,
        label:'过压预警'
      },
      {
        id:4,
        value:4,
        label:'过压报警'
      },
      {
        id:5,
        value:5,
        label:'电流预警'
      },
      {
        id:6,
        value:6,
        label:'电流告警'
      },
      {
        id:7,
        value:7,
        label:'电流报警'
      },
      {
        id:8,
        value:8,
        label:'漏电电流报警'
      },
      {
        id:9,
        value:9,
        label:'故障报警'
      },
      {
        id:10,
        value:10,
        label:'有功功率报警'
      },
      {
        id:11,
        value:11,
        label:'无功功率报警'
      },
      {
        id:12,
        value:12,
        label:'电量'
      },
      {
        id:13,
        value:13,
        label:'温度报警'
      },
    ],
    warningLevel:[
      {
        id:1,
        value:'全部',
        label:'全部'
      },
      {
        id:2,
        value:'一般',
        label:'一般'
      },
      {
        id:3,
        value:'紧急',
        label:'紧急'
      },
      {
        id:4,
        value:'严重',
        label:'严重'
      },
    ],
    tableData:[
      {
        id:1,
        warningNumber:20221123001,//报警编号
        owningUser:'XXXXX有限公司',//所属用户
        gatewayNumber:123123,//网关编号
        gatewayName:'一楼网关',//网关名称
        loopNumber:12312223,//回路编号
        loopName:'空调',//回路名称
        warningType:'欠压告警',//报警类型
        warningLevel:'一般',//报警等级
        warningDetail:'过压预警：当前值：230：预设值：220',//报警详情
        warningTime:'2022-11-12  12:00:00',//报警时间
      },
      {
        id:2,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'一般',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:3,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'一般',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:4,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'紧急',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:5,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'一般',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:6,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'一般',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:7,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'一般',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:8,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'严重',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:9,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'紧急',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
      {
        id:10,
        warningNumber:20221123001,
        owningUser:'XXXXX有限公司',
        gatewayNumber:123123,
        gatewayName:'一楼网关',
        loopNumber:12312223,
        loopName:'空调',
        warningType:'欠压告警',
        warningLevel:'一般',
        warningDetail:'过压预警：当前值：230：预设值：220',
        warningTime:'2022-11-12  12:00:00',
      },
    ]
   
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    
  },
}
