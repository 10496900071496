export default {
  namespaced: true,
  state: {
    productsSort:[
      {
        id:1,
        value1:'全部',
        label:'全部'
      },
      {
        id:2,
        value1:'断路器',
        label:'断路器'
      },
      {
        id:3,
        value1:'断路器',
        label:'断路器'
      },
      {
        id:4,
        value1:'断路器',
        label:'断路器'
      },
      {
        id:5,
        value1:'断路器',
        label:'断路器'
      },
    ],
    status:[
      {
        id:1,
        value2:'全部',
        label:'全部'
      },
      {
        id:2,
        value2:'上架',
        label:'上架'
      },
      {
        id:3,
        value2:'下架',
        label:'下架'
      },
    ],
    isDisplay:{ //是否展示新增采货单
      isShow:false
    },

    goodsType:[ //产品类型
      {
        id:1,
        value:1,
        label:'网关'
      },
      {
        id:2,
        value:2,
        label:'微端'
      },
      {
        id:3,
        value:3,
        label:'塑壳'
      },

    ],
    goodsFunct:[//是否带漏电
      {
        id:1,
        value:1,
        label:'带漏电保护'
      },
      {
        id:2,
        value:2,
        label:'不带漏电保护'
      }
    ],
    type:[//类别 
      {
        id:1,
        value:1,
        label:'网关',
      },
      {
        id:2,
        value:2,
        label:'微端',
      },
      {
        id:3,
        value:3,
        label:'塑壳',
      },
    ],
    goodsStatus:[
      {
        id:1,
        value:1,
        label:'上架'
      },
      {
        id:2,
        value:2,
        label:'下架'
      },
    ],
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    
  },
}
