export default {
  namespaced: true,
  state: {
    gateWayDetail:[
      {
        id:1,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },
      {
        id:2,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },
      {
        id:3,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:4,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },
      {
        id:5,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },
      {
        id:6,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:7,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:8,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:9,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:10,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:11,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:12,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },{
        id:13,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },
      {
        id:14,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },
      {
        id:15,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      },
      {
        id:16,
        gateName:"空调插座",
        loopNumber:123123,
        installData:'2022-12-20',
        isProtection:true,
      }
    ]
   
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
   
  },
}
