import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入Element UI框架
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入样式重置
import "./assets/css/reset.css"
// 引入思源黑体
import '../src/assets/font/font.css';

// 引入store的index.js
import store from "./store/index.js";

import './style/index.css'

import index from './utils'
Vue.use(index)

Vue.prototype.$URL = 'http://qiniu.lianbangiot.com/' // 线上资源地址

import JsonExcel from "vue-json-excel"; //引入excel插件
Vue.component("downloadExcel", JsonExcel);//注册

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
