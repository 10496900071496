export default {
  namespaced: true,
  state: {
    // 
    //选择框1的参数
    options1: [
      {
        id: 1,
        value1: '同意',
        label: '同意',
      },
      {
        id: 2,
        value1: '不同意',
        label: '不同意',
      },
      {
        id: 3,
        value1: '--',
        label: '--',
      },
    ],
    // 选择框2的参数
    options2: [
      {
        id: 1,
        value2: '网关',
        label: '网关',
      },
      {
        id: 2,
        value2: '路由',
        label: '路由',
      },
      {
        id: 3,
        value2: '继电器',
        label: '继电器',
      },
      {
        id: 4,
        value2: '总闸',
        label: '总闸',
      },
      {
        id: 5,
        value2: '单个闸口',
        label: '单个闸口',
      },
    ],
    // 表格数据
    tableData: [
      {
        id:1,
        numbering: '123123',//入库申请编号
        status: 2,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'--'//处理日期
      },
      {
        id:2,
        numbering: '123123',//入库申请编号
        status: 2,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'--'//处理日期
      },
      {
        id:3,
        numbering: '123123',//入库申请编号
        status: 2,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'--'//处理日期
      },
      {
        id:4,
        numbering: '123123',//入库申请编号
        status: 2,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'--'//处理日期
      },
      {
        id:5,
        numbering: '123123',//入库申请编号
        status: 0,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'2022-11-12'//处理日期
      },
      {
        id:6,
        numbering: '123123',//入库申请编号
        status: 0,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'2022-11-12'//处理日期
      },
      {
        id:7,
        numbering: '123123',//入库申请编号
        status: 1,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'2022-11-12'//处理日期
      },
      {
        id:8,
        numbering: '123123',//入库申请编号
        status: 1,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'2022-11-12'//处理日期
      },
      {
        id:9,
        numbering: '123123',//入库申请编号
        status: 1,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'2022-11-12'//处理日期
      },
      {
        id:10,
        numbering: '123123',//入库申请编号
        status: 2,//处理的状态：0--闭环 1--驳回 2--处理中
        applicant: '王五',//申请人
        applicateData:'2022-11-12',//申请日期
        applicateDevice:'网关',//申请设备
        withDrawalCause:"信息记录错误",//退库原因
        handlers:'管理员',//处理人
        dealResult:0,//处理结果 0--同意 1--不同意 2--代表 --
        dealData:'--'//处理日期
      },

    ],
    detailObj:{ //forms1和forms2的显示与隐藏
      isshow1:false,//已完成
      isshow2:false,//未完成
    },
    currentPage: 1,// 分页的页数
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    options1(state) {
      return state.options1
    },
    value(state) {
      return state.value
    },
    tableData(state){
      return state.tableData
    },
    //退库申请信息
    applicateNumber(state){
      return state.applicateInfo
    }
  },
}
