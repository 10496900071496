export default {
  namespaced: true,
  state: {
    isDisplay:{ //表单页是否显示
      isShow:false
    },
    userType:[
      {
        id:1,
        value: '1',
        label: '私企'
      },
      {
        id:2,
        value: '2',
        label: '国央企'
      },
      {
        id:3,
        value: '3',
        label: '外企'
      },
    ],
    tableData:[
      {
        id:1,
        accountNumber:'221123001',//账户编号
        userName:'admin',//用户名
        userType:'管理员',//用户类型
        loginAccount:'admin',//登入账号
        phone:13800001252,//手机号码
        email:'admin@admin.com',//邮箱
        createTime:'2022-11-12  16:02:03',//创建时间
      }
    ],
    ruleForm:{//新增账号
      loginAccount:'',//登入账号
      loginPassword:'',//登录密码
      userName:'',//用户名
      phone:null,//手机号码
      email:'',//邮箱
      userValue:'',//用户类型
    },
    rules:{ //定义表单验证规则
      loginAccount:[
        { required: true, message: '登录账号不能为空,请输入登录账号', trigger: 'blur' },
      ],
      loginPassword:[
        { required: true, message: '登录密码不能为空,请输入登录密码', trigger: 'blur' },
        { min: 6, max: 18, message: '长度在 6 到 8 个字符', trigger: 'blur' }
      ],
      userName:[
        { required: true, message: '用户名不能为空,请输用户名', trigger: 'blur' },
      ],
      phone:[
        { required: true, message: '手机号不能为空', trigger: 'blur' },
        { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
      ],
      userValue:[
        { required: true, message: '请选择用户类型', trigger: 'change' },
      ]
    },
    permissionsArr:[//权限
      {
        id:1,
        label:'设备入库',//一级标题
        children:[
          {
            id:6,
            label:'查看'
          },
          {
            id:7,
            label:'添加入库'
          },
        ]
      },
      {
        id:2,
        label:'客户管理',//一级标题
        children:[
          {
            id:8,
            label:'查看'
          },
          {
            id:9,
            label:'新增客户'
          },
          {
            id:10,
            label:'编辑'
          },
          {
            id:11,
            label:'账号开通'
          },
          {
            id:12,
            label:'授权管理'
          },
        ]
      },
      {
        id:3,
        label:'工程师管理',//一级标题
        children:[
          {
            id:13,
            label:'查看'
          },
          {
            id:14,
            label:'新增工程师'
          },
          {
            id:15,
            label:'新增派工'
          },
        ]
      },
      {
        id:4,
        label:'报警信息',//一级标题
        children:[
          {
            id:16,
            label:'报警事件查看'
          },
          {
            id:17,
            label:'设备离线查看'
          },
        ]
      },
      {
        id:5,
        label:'系统设置',//一级标题
        children:[
          {
            id:18,
            label:'账号查看'
          },
          {
            id:19,
            label:'授权码查看'
          },
          {
            id:20,
            label:'账号创建'
          },
          {
            id:21,
            label:'添加授权码'
          },
        ]
      },
    ]
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    
  },
}
