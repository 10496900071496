/** When your routing table is too long, you can split it into small modules**/
const downloadRouter = [
  {
    path: '/download-user',
    name: 'DownloadUser',
    meta: {
      title: 'APP下载'
    },
    component: () => import('@/views/download/user/index.vue')
  },
  {
    path: '/download-user/guidance',
    name: 'UserGuidance',
    meta: {
      title: 'APP下载'
    },
    component: () => import('@/views/download/user/guidance.vue')
  },
  {
    path: '/download-user/appdownload',
    name: 'UserAppDownload',
    meta: {
      title: '连邦翠鸟下载'
    },
    component: () => import('@/views/download/user/appdownload.vue')
  },
  {
    path: '/download-ele',
    name: 'DownloadEle',
    meta: {
      title: 'APP下载'
    },
    component: () => import('@/views/download/ele/index.vue')
  },
  {
    path: '/download-ele/guidance',
    name: 'EleGuidance',
    meta: {
      title: 'APP下载'
    },
    component: () => import('@/views/download/ele/guidance.vue')
  },
  {
    path: '/download-ele/appdownload',
    name: 'EleAppDownload',
    meta: {
      title: '数智电工下载'
    },
    component: () => import('@/views/download/ele/appdownload.vue')
  }
]

export default downloadRouter
