export default {
  namespaced: true,
  state: {
   gateNum:[
    {
      id:1,
      numbering:123456,
      label:123456
    },
    {
      id:2,
      numbering:123457,
      label:123457
    },
    {
      id:3,
      numbering:123458,
      label:123458
    },
    {
      id:4,
      numbering:123459,
      label:123459
    },
    {
      id:5,
      numbering:789789,
      label:789789
    },
   ],
  },
  mutations: {
    //修改数据的方法
  },
  actions: {
    //异步操作
  },
  getters: {
    //返回state中的数据
    
  },
}
