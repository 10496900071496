export default {
  namespaced: true,
  state: {//定义页面中的数据
    authorizationForm:{//授权认证的表单信息
      ConfigureAccount:'管理员',//配置账号
      value:'0',//是否作为电工账号
      loginAccount1:"13800002000",//登录账号
      authorizationData:"2024-12-31",//授权有效期
      electAccount:'电工1',//电工账户
      loginAccount2:'13800002000',//登录号码
    },
    deviceForm:{
      gateNum:55,//网关数
      loopNum:120,//回路数
    },
    followStatusArr:[
      {
        id:1,
        value:1,
        label:'潜在用户'
      },
      {
        id:2,
        value:2,
        label:'已合作'
      },
      {
        id:3,
        value:3,
        label:'已放弃'
      },
    ]
   
  },
  mutations: {//修改数据的方法
    
  },
  actions: {//异步操作
  
  },
  getters: {//返回state中的数据
   
  },
}
