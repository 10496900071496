import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from "element-ui";

Vue.use(VueRouter)

import downloadRouter from './modules/download'

const routes = [
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/login.vue"),
  },
  ...downloadRouter,
  // 首页
  {
    path: "/",
    name: "index",
    component: () => import("../views/index/index.vue"),
    redirect: "/gateWayWareHoushimg",
    children: [
      {
        path: "gateWayWareHoushimg",
        name: "网关入库",
        component: () =>
          import("../views/gateWayWareHousing/gateWayWareHousing.vue"),
        meta: {
          title: "网关入库",
        },
      },
      {
        path: "nicManage",
        name: "物联网卡管理",
        component: () => import("../views/nicManage/nicManage.vue"),
        meta: {
          title: "物联网卡管理",
        },
      },
      {
        path: "withDrawalApplication",
        name: "退库申请",
        component: () =>
          import("../views/withDrawalApplication/withDrawalApplication.vue"),
        meta: {
          title: "退库申请",
        },
      },
      {
        path: "customerInformation",
        name: "客户信息",
        component: () =>
          import("../views/customerInformation/customerInformation.vue"),
        meta: {
          title: "客户信息",
        },
      },
      {
        path: "userDetail",
        name: "用户详情页",
        component: () => import("../views/userDetail/userDetail.vue"),
      },
      {
        path: "customerEquipment",
        name: "客户设备",
        component: () =>
          import("../views/customerEquipment/customerEquipment.vue"),
        meta: {
          title: "客户设备绑定",
        },
      },
      {
        path: "equipmentDetail",
        name: "设备详情",
        component: () =>
          import("../views/customerEquipment/components/equipmentDetail.vue"),
      },
      {
        path: "agentManage",
        name: "代理商管理",
        component: () => import("../views/agentManage/agentManage.vue"),
        meta: {
          title: "代理商管理",
        },
      },
      {
        path: "agentDetail",
        name: "代理商详情",
        component: () =>
          import("../views/agentManage/components/agentDetail.vue"),
        meta: {
          title: "代理商详情",
        },
      },
      {
        path: "purchaseManage",
        name: "采购管理",
        component: () => import("../views/purchaseManage/purchaseManage.vue"),
        meta: {
          title: "采购管理",
        },
      },
      {
        path: "purchaseDetail",
        name: "采购详情",
        component: () =>
          import("../views/purchaseManage/components/purchaseDetail.vue"),
        meta: {
          title: "采购详情",
        },
      },
      // {
      //   path: "productInformMaintain",
      //   name: "产品信息维护 ",
      //   component: () =>
      //     import("../views/productInformMaintain/productInformMaintain.vue"),
      //   meta: {
      //     title: "产品信息维护",
      //   },
      // },
      {
        path: "warningInform",
        name: "报警事件",
        component: () => import("../views/warningInform/warningInform.vue"),
        meta: {
          title: "报警事件",
        },
      },
      {
        path: "offlineDevices",
        name: "离线设备",
        component: () => import("../views/offlineDevices/offlineDevices.vue"),
        meta: {
          title: "离线设备",
        },
      },
      {
        path: "accountManage",
        name: "账户管理",
        component: () => import("../views/accountManage/accountManage.vue"),
        meta: {
          title: "账户管理",
        },
      },
      {
        path: "journalManage",
        name: "日志管理",
        component: () => import("../views/journalManage/journalManage.vue"),
        meta: {
          title: "日志管理",
        },
      },
      {
        path: "uploadApp",
        name: "APP管理",
        component: () => import("../views/uploadApp/uploadApp.vue"),
        meta: {
          title: "APP管理",
        },
      },
      {
        path: "projectFiling",
        name: "项目报备",
        component: () => import("../views/projectFiling/projectFiling.vue"),
        meta: {
          title: "项目报备",
        },
      },
      {
        path: "filingDetail",
        name: "报备详情",
        component: () =>
          import("../views/projectFiling/components/filingDetail.vue"),
        meta: {
          title: "报备详情",
        },
      },
      //消息中心
      // {
      //   path: "messagenews",
      //   name: "消息中心",
      //   component: () =>
      //     import("../views/news/messagenews.vue"),
      //   meta: {
      //     title: "消息列表",
      //   },
      // },
    ],
  },
  {
    path: "/404",
    component: () => import("../views/error/error.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  const mobilePath = [
    '/download-user',
    '/download-user/guidance',
    '/download-user/appdownload',
    '/download-ele',
    '/download-ele/guidance',
    '/download-ele/appdownload',
    '/404',
  ]
  const whiteList = [
    '/login',
    ...mobilePath
  ] // 页面白名单

  // 限制手机端访问
  var str = navigator.userAgent;
  var ipad = str.match(/(iPad).*OS\s([\d_]+)/)
  var isIphone = !ipad && str.match(/(iPhone\sOS)\s([\d_]+)/)
  var isAndroid = str.match(/(Android)\s+([\d.]+)/)
  // 或者单独判断iphone或android
  if ((isIphone || isAndroid) && !mobilePath.includes(to.path)) {
    next('/404')
  } else {
    // 获取token，查看是否登录
    let token = document.cookie;
    console.log(token);
    if (!token && whiteList.includes(to.path)) {
      next()
    } else if (!token) {
      // 未登录
      Message.error('抱歉您还为登录,请先登录')
      localStorage.clear()
      next('/login')
    } else if (
      // 如果已经登录并且输入login地址, 强行跳转至首页
      to.path === '/login' &&
      token
    ) {
      next('/')
    } else {
      //已登录 放行
      next()
    }

  }
})
export default router
